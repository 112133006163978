import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import ProductPage from './pages/ProductPage'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import ProductResults from './pages/ProductResultsPage'
import ProductCategory from './pages/ProductCategoryPage'

import { HeroUIProvider } from '@heroui/react'

function App() {
  return (
    <HeroUIProvider>
      <Header />
      <main className='flow content-grid'>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/product/*' element={<ProductPage />} />
          <Route path='/results' element={<ProductResults />} />
          <Route path='/category/*' element={<ProductCategory />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </main>
    </HeroUIProvider>
  )
}

export default App
