import { useState, useEffect, useCallback } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export type DataTransformer<InDataType, OutDataType> = (
  data: InDataType,
) => OutDataType

const DEFAULT_CONFIG: AxiosRequestConfig = {
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000'
      : 'http://170.64.158.221:8000/',
  method: 'GET',
}

/* fetch data hook */
const useAPI = <InDataType, OutDataType = InDataType>(
  initialConfig: AxiosRequestConfig<InDataType> = DEFAULT_CONFIG,
  transformer?: DataTransformer<InDataType, OutDataType>,
) => {
  initialConfig = { ...DEFAULT_CONFIG, ...initialConfig }
  const [data, setData] = useState<OutDataType | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [config, setConfig] =
    useState<AxiosRequestConfig<InDataType>>(initialConfig)

  const updateConfig = useCallback(
    (updatedConfig: AxiosRequestConfig<InDataType>) => {
      setConfig(config => ({ ...config, ...updatedConfig }))
    },
    [],
  )

  useEffect(() => {
    if (!config.url) return
    setError(null)
    setLoading(true)
    axios
      .request(config)
      .then((response: AxiosResponse) => {
        setData(transformer ? transformer(response.data) : response.data)
      })
      .catch(error => {
        setError(error?.data?.response?.message ?? 'Something went wrong')
        setData(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [config])
  return { data, loading, error, updateConfig }
}

export default useAPI
