import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAPI from '../hooks/useAPI'
import { ListResults, SimpleProductResult } from '../types/backend'
import { Autocomplete, AutocompleteItem } from '@heroui/react'
import styled from 'styled-components'

const SEARCH_DEBOUNCE = 350
const MAX_RESULTS = 10
const ITEM_NO_RESULTS = 'NO_RESULTS'

const ProductItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  > img {
    max-height: 40px;
  }
`

export interface ProductSearchBarProps {
  placeholder: string
  width?: number | string
}

const ProductSearchBar = ({ placeholder }: ProductSearchBarProps) => {
  const { data, loading, updateConfig } =
    useAPI<ListResults<SimpleProductResult>>()
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState('')
  const navigate = useNavigate()

  // Debounce product search api calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query === '') return
      updateConfig({ url: 'search/', params: { query } })
    }, SEARCH_DEBOUNCE)
    return () => clearTimeout(timeoutId)
  }, [query, updateConfig])

  const getResultsParams = useCallback(() => {
    searchParams.set('query', query)
    searchParams.set('offset', '0')
    return searchParams.toString()
  }, [query, searchParams])

  const results: (SimpleProductResult & { onClick: () => void })[] =
    useMemo(() => {
      if (!data?.results?.length) {
        return []
      }

      return [
        ...data.results.map(item => ({
          ...item,
          onClick: () =>
            navigate({
              pathname: '/product/' + encodeURIComponent(item.model),
            }),
        })),
        {
          model: ITEM_NO_RESULTS,
          name:
            data.count > MAX_RESULTS
              ? `Show all ${data.count} results`
              : 'Showing all results',
          image: '',
          onClick: () =>
            navigate({
              pathname: '/results',
              search: getResultsParams(),
            }),
        },
      ]
    }, [data, getResultsParams])

  return (
    <Autocomplete<SimpleProductResult & { onClick: () => void }>
      className='max-w-xs'
      defaultItems={[]}
      items={results}
      label={placeholder}
      labelPlacement='inside'
      value={query}
      isClearable={true}
      isLoading={loading && !!query}
      onInputChange={setQuery}
      onChange={event => console.log(event.target.value)}
      maxListboxHeight={500}
    >
      {item => (
        <AutocompleteItem key={item.model} onPress={item.onClick}>
          <ProductItem>
            {item.image ? <img src={item.image} /> : null}
            {item.name}
          </ProductItem>
        </AutocompleteItem>
      )}
    </Autocomplete>
  )
}

export default ProductSearchBar
