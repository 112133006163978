import React from 'react'
import useAPI, { DataTransformer } from '../hooks/useAPI'
import { ResponsiveLine } from '@nivo/line'
import isEmpty from 'lodash/isEmpty'
import { StoreColours } from '../shared/constants'
import { GraphData, RawGraphData } from '../types/graphs'

export interface PriceHistoryGraphProps {
  model?: string
}

const transformer: DataTransformer<RawGraphData[], GraphData[]> = data => {
  return data.map(history => ({
    id: history.id,
    data: history.data.map(item => ({
      x: new Date(item.x),
      y: item.y,
    })),
  }))
}

const PriceHistoryGraph = ({ model }: PriceHistoryGraphProps) => {
  const { data, loading } = useAPI<RawGraphData[], GraphData[]>(
    {
      url: `product/${model}/history/`,
    },
    transformer,
  )

  if (loading) {
    return <div>Loading...</div>
  }

  if (isEmpty(data)) {
    return <div>No History Data</div>
  }

  return (
    <div
      style={{
        height: '500px',
      }}
    >
      {data && (
        <ResponsiveLine
          data={data}
          colors={d => StoreColours[d.id]}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{
            type: 'time',
            useUTC: false,
          }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          lineWidth={2}
          enableSlices={'x'}
          axisBottom={{
            format: '%d %b %y',
            tickValues: 8,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'date',
            legendOffset: 40,
            legendPosition: 'middle',
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'price',
            legendOffset: -50,
            legendPosition: 'middle',
            truncateTickAt: 0,
          }}
          pointSize={5}
          legends={[
            {
              anchor: 'bottom-right',
              toggleSerie: true,
              direction: 'column',
              translateX: 100,
              translateY: 0,
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </div>
  )
}
export default PriceHistoryGraph
