import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export type QueryParamTransformer<T> = (value: string) => T | null

export const DEFAULT_TRANSFORMER: QueryParamTransformer<unknown> = (
  value: string | null = null,
) =>
  value
    ? /^(true|false|null|undefined)$/.test(value)
      ? JSON.parse(value)
      : isNaN(Number(value))
        ? value
        : Number(value)
    : null

function useQueryParams<Params extends Record<string, unknown>>(
  defaultValue: Params,
  toType:
    | QueryParamTransformer<unknown>
    | {
        [Prop in keyof Partial<Params>]: QueryParamTransformer<Params[Prop]>
      } = DEFAULT_TRANSFORMER,
): Params & { updateParams: (newParams: Partial<Params>) => void } {
  const [searchParams, setSearchParams] = useSearchParams()
  const [params, setParams] = useState<Params>(defaultValue)

  const getTransformer = useCallback((key: keyof Params) => {
    if (typeof toType === 'function') {
      return toType
    } else if (typeof toType === 'object' && key in toType) {
      return toType[key] ?? ((value: string) => value)
    }
    return (value: string) => value
  }, [])

  useEffect(() => {
    const newValues = Object.assign(
      {},
      ...Array.from(searchParams.entries()).map(([key, value]) => ({
        [key]: getTransformer(key as keyof Params)(value),
      })),
    )
    setParams(newValues)
  }, [getTransformer, searchParams])

  const updateParams = useCallback(
    (newParams: Partial<Params>) => {
      setSearchParams(params => {
        Object.entries(newParams).forEach(([param, value]) => {
          if (!value && value !== 0) {
            params.delete(param)
          } else {
            params.set(param, String(value))
          }
        })
        return params
      })
    },
    [setSearchParams],
  )

  return { ...params, updateParams }
}

export default useQueryParams
