import { Pagination } from '@heroui/react'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

const ITEMS_PER_PAGE = 20

export interface PageEvent {
  activePage: number
  itemsPerPage: number
}

const StyledPaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: white;
`

export interface PaginatorProps {
  onPageEvent: (event: PageEvent) => void
  itemCount: number
}

const Paginator = ({ onPageEvent = () => {}, itemCount }: PaginatorProps) => {
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    onPageEvent({ activePage, itemsPerPage: ITEMS_PER_PAGE })
  }, [activePage, onPageEvent])

  const pageCount = useMemo(
    () => Math.max(1, Math.floor(itemCount / ITEMS_PER_PAGE)),
    [itemCount],
  )

  const handlePageChange = (page: number) => {
    setActivePage(page)
  }

  return (
    <StyledPaginatorContainer>
      <Pagination
        isCompact
        total={pageCount}
        siblings={3}
        onChange={handlePageChange}
      />
    </StyledPaginatorContainer>
  )
}

export default Paginator
